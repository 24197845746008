<template>
  <v-card>
    <v-card-text>
      <v-row class="py-4">
        <v-col cols="12" class="text-center text-heading-1">
          アフター対応管理
          <div>
            <v-btn depressed class="close-button" fab color="#E27C7C" width="20" height="20"
              @click="$emit('close-dialog')">
              <v-icon size="14">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <validation-observer ref="observer">
        <form @submit.prevent="save">
          <v-row>
            <v-col cols="12">
              <div class="form-header mb-2">日付</div>
              <div>
                <validation-provider v-slot="{ errors }" name="tasks" rules="">
                  <v-dialog ref="dialogDate" v-model="dialog.date" :return-value.sync="data.date" persistent
                    width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="data.date" readonly class="form-text" v-bind="attrs" v-on="on" dense
                        hide-details="auto" outlined :error-messages="errors" :error="!!errors.length"
                        placeholder="日付選択"></v-text-field>
                    </template>
                    <v-date-picker :day-format="formatDate" v-model="data.date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialog.date = false">
                        キャンセル
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.dialogDate.save(data.date)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </validation-provider>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="form-header mb-2">対応種別</div>
              <div>
                <validation-provider v-slot="{ errors }" name="クレーム分類" rules="">
                  <v-radio-group v-model="data.is_paid" row hide-details="auto" class="mt-0 pt-0"
                    :error="!!errors.length" :error-messages="errors">
                    <v-radio label="有償" :value="1"></v-radio>
                    <v-radio label="無償" :value="0"></v-radio>
                    <span class="unselect" @click="data.is_paid = -1">選択解除</span>
                  </v-radio-group>
                </validation-provider>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="form-header mb-2">部屋番号</div>
              <div>
                <validation-provider v-slot="{ errors }" name="部屋番号" rules="">
                  <v-text-field class="form-text" dense hide-details="auto" outlined placeholder=""
                    v-model="data.room_number" :error-messages="errors" :error="!!errors.length"></v-text-field>
                </validation-provider>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="form-header mb-2">依頼項目</div>
              <div>
                <validation-provider v-slot="{ errors }" name="依頼項目" rules="">
                  <v-radio-group row hide-details class="mt-0 pt-0" :error-messages="errors" :error="!!errors.length">
                    <template v-for="(subItem, subIndex) in items.reasons">
                      <InputCheckbox :key="`checkbox-reason-${subIndex}`" :subItem="subItem"
                        :dataModel.sync="data.reason" />
                      <template v-if="
                        subItem.extra_type &&
                          getIndexByItem(data.reason, subItem) != -1
                      ">
                        <v-text-field :key="`textfield-reason-${subIndex}`" v-if="subItem.extra_type" v-model="
                          data.reason[getIndexByItem(data.reason, subItem)]
                            .text_value
                        " dense outlined class="form-text ml-2 mr-2 mb-2" hide-details height="10"
                          @input="changeInput($event, data.reason, subItem)">
                        </v-text-field>
                      </template>
                    </template>
                  </v-radio-group>
                </validation-provider>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="form-header mb-2">対応方法</div>
              <div>
                <validation-provider v-slot="{ errors }" name="対応方法" rules="">
                  <v-radio-group row hide-details class="mt-0 pt-0" :error-messages="errors" :error="!!errors.length">
                    <template v-for="(subItem, subIndex) in items.methods">
                      <InputCheckbox :key="`checkbox-reason-${subIndex}`" :subItem="subItem"
                        :dataModel.sync="data.method" />
                      <template v-if="
                        subItem.extra_type &&
                          getIndexByItem(data.method, subItem) != -1
                      ">
                        <v-text-field :key="`textfield-reason-${subIndex}`" v-if="subItem.extra_type" v-model="
                          data.method[getIndexByItem(data.method, subItem)]
                            .text_value
                        " dense outlined class="form-text ml-2 mr-2 mb-2" hide-details height="10"
                          @input="changeInput($event, data.method, subItem)">
                        </v-text-field>
                      </template>
                    </template>
                  </v-radio-group>
                </validation-provider>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="form-header mb-2">対応結果</div>
              <div>
                <validation-provider v-slot="{ errors }" name="対応結果" rules="">
                  <v-radio-group row hide-details class="mt-0 pt-0" :error-messages="errors" :error="!!errors.length">
                    <template v-for="(subItem, subIndex) in items.after_treatments">
                      <InputCheckbox :key="`checkbox-reason-${subIndex}`" :subItem="subItem"
                        :dataModel.sync="data.after_treatment" />
                      <template v-if="
                        subItem.extra_type &&
                          getIndexByItem(data.after_treatment, subItem) != -1
                      ">
                        <v-text-field :key="`textfield-reason-${subIndex}`" v-if="subItem.extra_type" v-model="
                          data.after_treatment[
                            getIndexByItem(data.after_treatment, subItem)
                          ].text_value
                        " dense outlined class="form-text ml-2 mr-2 mb-2" hide-details height="10" @input="
                          changeInput($event, data.after_treatment, subItem)
                        ">
                        </v-text-field>
                      </template>
                    </template>
                  </v-radio-group>
                </validation-provider>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="form-header mb-2">対応者</div>
              <div>
                <validation-provider v-slot="{ errors }" name="対応者" rules="">
                  <v-combobox class="form-text" v-model="data.selected_user_id" :items="allUsers" item-text="last_name"
                    dense small-chips hide-details="auto" outlined placeholder="未選択" multiple :error-messages="errors"
                    :error="!!errors.length">
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip v-if="item === Object(item)" v-bind="attrs" :input-value="selected" label small>
                        <span class="pr-2">
                          {{ item.last_name }} {{ item.first_name }}
                        </span>
                        <v-icon small @click="parent.selectItem(item)">
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <div class="text-body-1">
                        {{ item.last_name }} {{ item.first_name }}
                      </div>
                    </template>
                  </v-combobox>
                </validation-provider>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="form-header mb-2">対応者初見</div>
              <div>
                <validation-provider v-slot="{ errors }" name="対応者初見" rules="">
                  <v-textarea class="form-text" rows="2" dense v-model="data.memo" hide-details="auto" outlined
                    placeholder="" :error="!!errors.length" :error-messages="errors">
                  </v-textarea>
                </validation-provider>
              </div>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-row align="center">
                <!--                <v-col-->
                <!--                  class="text-right"-->
                <!--                  cols="auto"-->
                <!--                  v-if="this.afterServiceItem && this.afterServiceItem.id"-->
                <!--                >-->
                <!--                  <v-btn @click="deleteTask" color="#AD4545" text>-->
                <!--                    <v-icon left> mdi-delete </v-icon> 削除する</v-btn-->
                <!--                  >-->
                <!--                </v-col>-->
                <v-col cols="12">
                  <v-btn @click="save" color="#4F55A7" class="white--text" width="100">登録
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import dayjs from "@/plugins/dayjs";
import InputCheckbox from "@/components/ui/InputCheckbox";
export default {
  name: "AfterServiceDialog",
  components: {
    InputCheckbox
  },
  props: {
    afterServiceItem: {
      type: Object,
      default: () => { },
      required: false
    },
    customer: {
      type: Object,
      required: true
    },
    project: {
      type: Object,
      required: true
    },
    claimCategories: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(["user", "allUsers"]),
    tasks() {
      return this.$store.getters.customerTasksById.map(tasks => {
        return {
          value: tasks.id,
          text: tasks.id + " / " + tasks.date + " / " + tasks.service.name
        };
      });
    }
  },
  data() {
    return {
      dialog: {
        coating_after_add: false,
        date: false
      },
      data: {
        date: dayjs().format("YYYY-MM-DD"),
        is_paid: "0",
        room_number: "",
        reason: [],
        after_treatment: [],
        user_id: null,
        selected_user_id: null,
        method: [],
        memo: null
      },

      items: {
        after_treatments: [
          {
            text: "成功",
            value: 0
          },
          {
            text: "微妙",
            value: 1
          },
          {
            text: "お客様納得",
            value: 2
          },
          {
            text: "失敗",
            value: 3
          },
          {
            text: '状態の説明',
            value: 4
          },
          {
            text: "その他",
            extra_type: "with_text",
            value: 5
          }
        ],
        reasons: [
          {
            text: "ゴミ混入",
            value: 0
          },
          {
            text: "張替",
            value: 1
          },
          {
            text: "キズ",
            value: 2
          },
          {
            text: "ムラ",
            value: 3
          },
          {
            text: 'カスレ・あな',
            value: 5
          },
          {
            text: "その他",
            extra_type: "with_text",
            value: 4
          }
        ],
        methods: [
          {
            text: "訪問再コート",
            value: 0
          },
          {
            text: "社内持ち込み再コート",
            value: 1
          },
          {
            text: "スプレーぼかし",
            value: 2
          },
          {
            text: "リペア含む",
            value: 3
          },

          {
            text: "剥離再コート",
            value: 4
          },
          {
            text: "アルコール除去",
            value: 5
          },
          {
            text: "重ね散布",
            value: 6
          },

          {
            text: "その他",
            extra_type: "with_text",
            value: 7
          }
        ]
      }
    };
  },
  watch: {
    afterServiceItem: function () {
      this.data = {
        date: dayjs().format("YYYY-MM-DD"),
        is_paid: "0",
        room_number: "",
        reason: [],
        after_treatment: [],
        user_id: null,
        selected_user_id: null,
        method: [],
        memo: null
      };
      this.patchEditFormValues();
    }
  },
  created() {
    this.patchEditFormValues();
  },
  methods: {
    ...mapMutations(["confirmationModal"]),
    formatDate(e) {
      return dayjs(e).format("D");
    },
    getIndexByItem(data, item) {
      return data
        .map(item => `${item.text}${item.id}`)
        .indexOf(`${item.text}${item.id}`);
    },

    changeInput(e, data, item) {
      let d = data[this.getIndexByItem(data, item)];
      // eslint-disable-next-line no-prototype-builtins
      if (d.hasOwnProperty("text_value") && !e) {
        delete d.text_value;
        return;
      }
      d.text_value = e;
    },
    patchEditFormValues() {
      if (this.afterServiceItem && this.afterServiceItem.id) {
        this.data.date = this.afterServiceItem.date;
        this.data.is_paid = this.afterServiceItem.is_paid;
        this.data.room_number = this.afterServiceItem.room_number;
        this.data.reason = this.afterServiceItem.reason;
        this.data.after_treatment = this.afterServiceItem.after_treatment;
        this.data.selected_user_id = this.afterServiceItem.user?.map(user => {
          return this.allUsers.find(u => u.id === user.user_id);
        });
        this.data.method = this.afterServiceItem.method;
        this.data.memo = this.afterServiceItem.memo;
      } else {
        this.data.date = dayjs().format("YYYY-MM-DD");
        this.data.is_paid = -1;
        this.data.room_number = "";
        this.data.reason = [];
        this.data.after_treatment = [];
        this.data.selected_user_id = null;
        this.data.method = [];
        this.data.memo = null;
      }
    },
    save() {
      this.$refs.observer.validate().then(async success => {
        if (!success) {
          return;
        }
        let data = this.data;
        data.svc_coating_id = this.project?.svc_coating_id;
        data.user_id = data.selected_user_id?.map(user => user.id);
        if (this.afterServiceItem && this.afterServiceItem.id) {
          await this.$store
            .dispatch("UPDATE_COATING_AFTER", {
              id: this.afterServiceItem.id,
              data
            })
            .then(
              response => {
                if (response.data.status == "success") {
                  this.$emit("close-dialog");
                }
              },
              error => {
                throw error;
              }
            );
        } else {
          await this.$store.dispatch("CREATE_COATING_AFTER", data).then(
            response => {
              if (response.data.status == "success") {
                this.$emit("close-dialog");
              }
            },
            error => {
              throw error;
            }
          );
        }
        await this.$store.dispatch("CUSTOMER_GET_ALL_AFTER_SERVICE", {
          customer_id: this.customer.id,
          paginate: 10,
          coating_id: this.project?.svc_coating_id
        });
      });
    },
    deleteTask() {
      this.confirmationModal({
        text: "このアクションを実施してよろしいですか",
        action: {
          process: {
            color: "red",
            text: "実施する"
          },
          cancel: {
            color: "secondary",
            text: "キャンセル"
          }
        },
        func: this.processDelete
      });
    },
    processDelete() {
      if (this.afterServiceItem && this.afterServiceItem.id) {
        let afterServiceId = this.afterServiceItem.id;
        this.$store.dispatch("DELETE_COATING_AFTER", afterServiceId).then(
          response => {
            if (response.data.status == "success") {
              this.$emit("close-dialog");
            }
          },
          error => {
            throw error;
          }
        );
      }
    }
  }
};
</script>

<style lang="scss" src="./AfterServiceDialog.scss" scoped>

</style>
