<template>
  <v-container class="info-box mt-4 pa-5">
    <v-dialog ref="afterServiceDialog" v-model="afterServiceDialog" width="500">
      <AfterServiceDialog
        :after-service-item="afterServiceItem"
        :customer="customer"
        :claimCategories="claimCategories"
        :project="project"
        @close-dialog="afterServiceDialog = false; afterServiceItem = {}"
      ></AfterServiceDialog>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="info-box-headline">
        <v-row align="center">
          <v-col cols="auto">
            <v-icon class="mr-4" color="#4F55A7" size="10"
              >mdi-checkbox-blank-circle</v-icon
            >
            アフター
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="flex-grow-1">
        <template v-for="item in afterServices">
          <v-row no-gutters :key="`after-service-1-${item.id}`" align="center">
            <v-col cols="6" class="info-box-date text-heading-3">
              {{ item.date | formatDate }}
            </v-col>
            <v-col cols="6" class="info-box-date text-right text-heading-3">
             <span class="label"> 部屋番号：</span>{{ item.room_number }}
            </v-col>
            <v-col cols="12" class="info-box-date text-right text-heading-3">
             <span class="label"> 対応種別：</span>{{ paidStatus[item.is_paid] ? paidStatus[item.is_paid] : '' }}
            </v-col>

            <v-col cols="9">
              <v-row
                no-gutters
                :key="`after-service-2-${item.id}`"
                class="mb-3"
              >
                <v-col cols="3" class="info-box-date text-heading-3">
                 <span class="label"> 依頼項目 </span>
                </v-col>

                <v-col
                  cols="9"
                  class="info-box-date text-heading-3"
                >
                  {{ showText(item.reason) }}
                </v-col>

                <v-col cols="3" class="info-box-date text-heading-3 mtop-7">
                 <span class="label"> 対応方法 </span>
                </v-col>

                <v-col
                  cols="9"
                  class="info-box-date text-heading-3 mtop-7"
                >
                  {{ showText(item.method) }}
                </v-col>

                <v-col cols="3" class="info-box-date text-heading-3 mtop-7">
                <span class="label">  対応結果 </span>
                </v-col>

                <v-col
                  cols="9"
                  class="info-box-date text-heading-3 mtop-7"
                >
                  {{ showText(item.after_treatment) }}
                </v-col>

                <v-col cols="3" class="info-box-date text-heading-3">
                 <span class="label"> 対応者 </span>
                </v-col>

                <v-col
                  cols="9"
                  class="info-box-date white-space-prewrap text-heading-3"
                >
                  {{ showUser(item.user) }}
                </v-col>

                <v-col cols="3" class="info-box-date text-heading-3 mtop-7">
                 <span class="label"> 対応初見 </span>
                </v-col>


                <v-col
                  cols="9"
                  class="info-box-date white-space-prewrap text-heading-3 mtop-7"
                >
                  {{ item.memo }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="text-right">
              <v-btn fab color="primary" x-small @click="editAfterService(item)">
                <v-icon size="14">$edit</v-icon>
              </v-btn>
            </v-col>
                  <v-col cols="12" class="mb-3">
                  <v-divider />
                </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AfterServiceDialog from "@/components/layout/customer/after-service/AfterServiceDialog.vue";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";

export default {
  name: "AfterServiceList",
  components: { AfterServiceDialog },
  props: ["customer", "sites", "claimCategories", "project"],
  computed: {
    ...mapGetters(["afterServices"]),
    selectedSite() {
      return this.sites.find(site => site.id == this.$route.params.site_id);
    },
    projects() {
      return this.selectedSite.projects;
    }
  },
  data() {
    return {
      paidStatus: [
         "無償",
         "有償"
      ],
      afterServiceDialog: false,
      afterServiceItem: {}
    };
  },
  filters: {
    formatDate(date) {
      if (!date) return "-";
      return dayjs(date).format("YYYY年M月D日");
    }
  },
  methods: {
    showText(array) {
      if (!array?.length) return "-";
      if (array) {
        return array
          .map(c => {
            return (c?.extra_type === 'with_text' ? `${c.text} (${c?.text_value || ''})` : c.text) || "";
          })
          .join(", ");
      }
    },
    showUser(array) {
      if (!array?.length) return "-";
      if (array) {
        return array
          .map(c =>  `${c.user?.last_name || ""} ${c.user?.first_name || ""}`)
          .join(", ");
      }
    },
    editAfterService(item) {
      this.afterServiceDialog = true;
      this.afterServiceItem = item;
    }
  }
};
</script>

<style lang="scss" scoped>
.info-box {
  background: #f8f9ff;
  box-shadow: 0 1px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  .white-space-prewrap{
   white-space: pre-wrap;
  }
  .info-box-date {
    .label {
    color: #828282;
    }
  }
  .date {
    font-size: 20px !important;
  }
  &-headline {
    font-size: 18px;
    color: #4f55a7;
    font-weight: 700;
  }
  .mtop-7 {
    margin-top: 7px;
  }
  font-size: 16px;

  &-date {
    color: #333;
  }

  &-title {
    color: #878787;
  }

  &-content {
    color: #333333;
    word-break: break-all;
  }
}
</style>
