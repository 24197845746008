<template>
  <v-checkbox :label="subItem.text || subItem.name" :value="subItem" v-model="data" class="mx-2 mt-0" hide-details>
  </v-checkbox>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "InputCheckbox",
  props: {
    dataModel: {
      required: true
    },
    subItem: {
      required: true
    }
  },
  data() {
    return {
      text_value: null
    }
  },
  computed: {
    data: {
      get() {
        let data = cloneDeep(this.dataModel);
        return data.map(item => {
          if (item.extra_type) {
            this.text_value = item.text_value
            delete item.text_value
          } 
          // if (item.text_value) delete item.text_value;
          return {
            ...item
          };
        });
      },
      set(value) {
        let dataModel = cloneDeep(this.dataModel);
        let val = cloneDeep(value);
        
        val.forEach(v => {
          let data = dataModel.find(item => item.value === v.value);
          // eslint-disable-next-line no-prototype-builtins
          if (data && data.hasOwnProperty("extra_type")) {
            // v.text_value = this.text_value
            v.text_value = this.text_value;
            this.text_value = null
          }
        });
        return this.$emit("update:dataModel", val);
      }
    }
  }
};
</script>
