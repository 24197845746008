<template>
  <div class="mt-4">
    <v-dialog ref="afterServiceDialog" v-model="afterServiceDialog" width="500">
      <AfterServiceDialog
        :after-service-item="claimItem"
        :customer="customer"
        :claimCategories="claimCategories"
        :project="selectedProject"
        @close-dialog="afterServiceDialog = false"
      ></AfterServiceDialog>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="flex-grow-1 text-right">
        <v-btn
          height="28"
          class="btn-new-task"
          color="#CFD3FE"
          depressed
          min-width="100"
          @click="addAfterService"
        >
          <v-icon left> mdi-plus </v-icon> アフター
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <AfterServiceList
          :customer="customer"
          :sites="sites"
          :claimCategories="claimCategories"
          :project="selectedProject"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AfterServiceDialog from "@/components/layout/customer/after-service/AfterServiceDialog.vue";
import AfterServiceList from "@/components/layout/customer/AfterServiceList";
export default {
  name: "AfterService",
  props: {
    project_id: {
      type: Number,
      required: true
    }
  },
  components: { AfterServiceList, AfterServiceDialog },
  computed: {
    customer() {
      return this.$store.getters.getCustomer;
    },
    afterServices() {
      return this.$store.getters.afterServices;
    },
    sites() {
      return this.customer.sites.map((sites, key) => {
        return { value: key, text: sites.name };
      });
    },
    selectedSite() {
      return this.customer.sites.find(site => site.id == this.$route.params.site_id);
    },
    projects() {
      return this.selectedSite?.projects;
    },
    selectedProject() {
      return this.selectedSite.projects.find(project => project.id == this.project_id);
    },
    claimCategories() {
      return this.$store.getters.allClaimCategories.map(ClaimCategories => {
        return { value: ClaimCategories.id, text: ClaimCategories.name };
      });
    }
  },
  created() {
    this.getDataFromApi();
  },
  data() {
    return {
      afterServiceDialog: false,
      claimItem: {},
      data: {
        site: 0
      }
    };
  },
  methods: {
    addAfterService() {
      this.afterServiceDialog = true;
      this.claimItem = {};
    },
    async getDataFromApi() {
      let params = {
        tag_type: 2
      };
      await this.$store.dispatch("GET_CLAIM_CATEGORIES", params);
      await this.$store.dispatch("USER_GET_ALL");
      this.loading = false;
    }
  }
};
</script>
